.login-pagina {

    // :root {
    --input-padding-x: 1.5rem;
    --input-padding-y: 0.75rem;
    // }
    
    .login,
    .image {
        min-height: 100vh;
    }

    

    .alerta-erro {
        margin-top: 5px;
        border-radius: 2rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;


        p {
            margin: 0;
            
        }
    }
    
    .bg-image {
        // background-image: url('https://source.unsplash.com/WEQbe2jBg40/600x1200');
        background-size: cover;
        background-position: center;
        align-items: center;
        justify-content: center;

        img {
            max-width: 80%;
            width: 100%;
        }
    }
    
    .login-heading {
        font-weight: 300;
    }
    
    .btn-login {
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        padding: 0.75rem 1rem;
        border-radius: 2rem;
    }
    
    .form-label-group {
        position: relative;
        margin-bottom: 1rem;
    }
    
    .form-label-group > .form-control,
    .form-label-group>label {
        padding: var(--input-padding-y) var(--input-padding-x);
        height: auto;
        border-radius: 2rem;

        &.input-erro {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    
    .form-label-group>label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        /* Override default `<label>` margin */
        line-height: 1.5;
        color: #495057;
        cursor: text;
        /* Match the input under the label */
        border: 1px solid transparent;
        border-radius: .25rem;
        transition: all .1s ease-in-out;
    }
    
    .form-label-group input::-webkit-input-placeholder {
        color: transparent;
    }
    
    .form-label-group input:-ms-input-placeholder {
        color: transparent;
    }
    
    .form-label-group input::-ms-input-placeholder {
        color: transparent;
    }
    
    .form-label-group input::-moz-placeholder {
        color: transparent;
    }
    
    .form-label-group input::placeholder {
        color: transparent;
    }
    
    .form-label-group input:not(:placeholder-shown) {
        padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
        padding-bottom: calc(var(--input-padding-y) / 3);
    }
    
    .form-label-group input:not(:placeholder-shown)~label {
        padding-top: calc(var(--input-padding-y) / 3);
        padding-bottom: calc(var(--input-padding-y) / 3);
        font-size: 12px;
        color: #777;
    }
}