.tabela {
    thead {

        tr {

            th {
                padding: 0.5rem;
                background: #dcdcdc;
                color: #313131;
                text-shadow: 1px 1px 3px #e6e6e6;
                font-weight: bold;
                white-space: nowrap;
                text-align: left;
                border: none;
                border-right: 2px solid white;

                &:first-child {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }

                &:last-child {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    border: none;
                }
            }

            &:nth-child(2) {

                th {
                    border-top: 2px solid white;

                    &.tc {
                        text-align: center;
                        font-size: 0.7rem;
                        font-weight: normal;
                    }
                }
            }
        }
    }

    tbody {

        tr {

            &:hover {
                background: linear-gradient(to right, #f6f6f6 0%, rgba(246, 246, 246, 0.69) 73%, rgba(255, 255, 255, 0.42) 100%);
            }

            td {
                padding: 0.5rem;
                border: none;

                

                &.codigo {
                    width: 1%;
                    text-align: center;
                }

                &.acoes {
                    width: 1%;
                    white-space: nowrap;
                }

                &.estreito {
                    width: 1%;
                }


            }
        }
    }
}