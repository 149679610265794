.painel-sidebar {

    // --width-sidebar: 290px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--width-sidebar);
    display: block;
    z-index: 1;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    background-position: center center;
    overflow: auto;
    max-height: 100%;
    height: 100%;
    -webkit-transition-property: top, bottom;
    transition-property: top, bottom;
    -webkit-transition-duration: .2s, .2s;
    transition-duration: .2s, .2s;
    -webkit-transition-timing-function: linear, linear;
    transition-timing-function: linear, linear;
    -webkit-overflow-scrolling: touch;
    transition: width 0.3s;

    &.encolher {
        // --width-sidebar: 90px;

        .itens {
            

            .logo {
                min-height: 60.6px;
                button {

                    .icone {
                        // font-size: 21px;
                    }
                }

                .texto-simples {
                    display: none;
                }

                .logo-imagem {
                    display: none;
                }
            }

            .nav {

                li {
                    // overflow: hidden;
                    .nav-link {
                        // padding: 0.25rem;
                        overflow: hidden;
                        .icone {
                            // font-size: 21px;
                        }

                        > p {
                            // display: none;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    &:before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: .33;
        background: #00546b;
    }

    &:after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, #00546b 0%, #00546b 100%);
        background-size: 150% 150%;
        opacity: .77;
        z-index: 3;
    }

    .itens {
        position: relative;
        max-height: calc(100vh - 75px);
        min-height: 100%;
        overflow: auto;
        width: var(--width-sidebar);
        z-index: 4;
        padding-bottom: 100px;
        transition: width 0.3s;
        overflow-x: hidden;
        overflow-y: hidden;
        
        .logo {
            position: relative;
            z-index: 4;
            padding: 10px 15px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: center;
            height: 60.6px;

            button {
                background: none;
                border:none;

                &:focus {
                    outline: 0;
                }

                .icone {
                    color: white;
                }
            }

            .texto-simples {
                text-transform: uppercase;
                padding: 5px 0px;
                display: block;
                font-size: 18px;
                color: #FFFFFF;
                text-align: center;
                font-weight: 400;
                line-height: 30px;
                text-decoration: none;
                flex-grow: 1;
            }

            .logo-imagem {
                flex-grow: 1;
                text-align: center;
                
                img {
                    width: 70%;
                    height: auto;
                    vertical-align: middle;
                    max-height: 100%;
                }

            }
        }

        .nav {
            margin-top: 20px;
            float: none;
            display: block;

            li {

                &.active {

                    .nav-link {
                        color: #FFFFFF;
                        opacity: 1;
                        background: rgba(255, 255, 255, 0.23);
                    }
                }

                .nav-link {
                    transition: all 150ms ease-in;
                    text-transform: uppercase;
                    line-height: 30px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #FFFFFF;
                    margin: 5px 15px;
                    opacity: .86;
                    border-radius: 4px;
                    display: block;
                    padding: 10px 15px;
                    display: flex;
                    align-items: center;
                    // padding: 7px 7px;
                    transition: width 0.3s;

                    &:hover {
                        background: rgba(255, 255, 255, 0.13);
                        opacity: 1;
                    }

                    .icone {
                        display: inline-block;
                        font: normal normal normal 14px/1 'nucleo-icons';
                        font-size: inherit;
                        speak: none;
                        text-transform: none;
                        -webkit-font-smoothing: antialiased;
                        font-size: 28px;
                        margin-right: 15px;
                        width: 30px;
                        text-align: center;
                        vertical-align: middle;
                        float: left;
                    }

                    p {
                        margin: 0;
                        line-height: 31px;
                        font-size: 15px;
                        font-weight: 600;
                        font-weight: bold;
                        text-transform: uppercase;
                        display: inline-flex;
                        line-height: 20px;
                        text-transform: capitalize;
                        // transition: display 0.3s;
                        opacity: 1;
                        transition: opacity 0.3s linear;
                        line-height: 14px;
                    }
                }
            }
        }
        
    }

    .itens-fundo {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
        opacity: .77;
        
    }
}