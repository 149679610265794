.painel-consulta {

    .card-header {
        border: none;
        background: none;

        &.cabecalho-atendimento {

            h4 {
                font-size: 1.17rem;
                font-weight: normal;
            }
        }
    }

    .parametros {

        .parametros-inicial {
            display: flex;
            align-items: center;

            .botao-cadastrar {
                // flex-grow: 1;
                white-space: nowrap;
            }

            .campo-consulta {
                // flex-grow: 1;
            }

            .campo-flags {
                flex-grow: 1;
                white-space: nowrap;
            }

            > * {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .parametros-campos {
            display: flex;

            > * {
                width: calc(100% / 3);
            }
        }

        
    }

    .card-body {
        position: relative;
    }

    

}